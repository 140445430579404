import * as PIXI from 'pixi.js';

import * as Core from '../../core';

const shields = new Core.ResourceLoader(null);

const svgLoadOptions = {
    resourceOptions: { 
        width: 512,
        height: 512
     },
    mipmap: PIXI.MIPMAP_MODES.ON
};

const svgLoadOptions_capital = {
    resourceOptions: { 
        width: 800,
        height: 800
     },
    mipmap: PIXI.MIPMAP_MODES.ON
};

let loadResources = (shields, shipArray) => {
    shipArray.forEach((ship) => {
        shields.addTextureResource(ship.name + '_frame0', '/sprites/shields/' + ship.id + '_' + ship.name + '_shield0.svg', ship.cap ? svgLoadOptions_capital : svgLoadOptions);
        shields.addTextureResource(ship.name + '_frame1', '/sprites/shields/' + ship.id + '_' + ship.name + '_shield1.svg', ship.cap ? svgLoadOptions_capital : svgLoadOptions);
        shields.addTextureResource(ship.name + '_frame2', '/sprites/shields/' + ship.id + '_' + ship.name + '_shield2.svg', ship.cap ? svgLoadOptions_capital : svgLoadOptions);
    });
};

let shipArray = [
    { id: 0, name: 'human_scout', cap: false },
    { id: 1, name: 'human_freighter', cap: false },
    { id: 2, name: 'human_frigate', cap: false },
    { id: 3, name: 'human_hornet', cap: false },
    { id: 4, name: 'feral_raider', cap: false },
    { id: 5, name: 'feral_freighter', cap: false },
    { id: 6, name: 'feral_marauder', cap: false },
    { id: 7, name: 'feral_assaulter', cap: false },
    { id: 8, name: 'alkari_runner', cap: false },
    { id: 9, name: 'alkari_hauler', cap: false },
    { id: 10, name: 'alkari_chaser', cap: false },
    { id: 11, name: 'alkari_deathwing', cap: false },
    { id: 12, name: 'tuskan_scout', cap: false },
    { id: 13, name: 'tuskan_freighter', cap: false },
    { id: 14, name: 'tuskan_interceptor', cap: false },
    { id: 15, name: 'tuskan_avenger', cap: false },
    { id: 16, name: 'light_destroyer', cap: true },
    { id: 17, name: 'large_freighter', cap: true },
    { id: 18, name: 'super_freighter', cap: true },
    { id: 19, name: 'armed_freighter', cap: true },
    { id: 20, name: 'missile_destroyer', cap: true },
    { id: 21, name: 'bird_of_prey', cap: true },
    { id: 22, name: 'desolator', cap: true },
    { id: 23, name: 'carrier', cap: true }
];

loadResources(shields, shipArray);

exports.shields = shields;