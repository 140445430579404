import * as Core from '../../core';

const ship_manifests = new Core.ResourceLoader(null);

ship_manifests.addJsonResource('human_scout', '/ships/0_human_scout.json');
ship_manifests.addJsonResource('human_freighter', '/ships/1_human_freighter.json');
ship_manifests.addJsonResource('human_frigate', '/ships/2_human_frigate.json');
ship_manifests.addJsonResource('human_hornet', '/ships/3_human_hornet.json');
ship_manifests.addJsonResource('feral_raider', '/ships/4_feral_raider.json');
ship_manifests.addJsonResource('feral_freighter', '/ships/5_feral_freighter.json');
ship_manifests.addJsonResource('feral_marauder', '/ships/6_feral_marauder.json');
ship_manifests.addJsonResource('feral_assaulter', '/ships/7_feral_assaulter.json');
ship_manifests.addJsonResource('alkari_runner', '/ships/8_alkari_runner.json');
ship_manifests.addJsonResource('alkari_hauler', '/ships/9_alkari_hauler.json');
ship_manifests.addJsonResource('alkari_chaser', '/ships/10_alkari_chaser.json');
ship_manifests.addJsonResource('alkari_deathwing', '/ships/11_alkari_deathwing.json');
ship_manifests.addJsonResource('tuskan_scout', '/ships/12_tuskan_scout.json');
ship_manifests.addJsonResource('tuskan_freighter', '/ships/13_tuskan_freighter.json');
ship_manifests.addJsonResource('tuskan_interceptor', '/ships/14_tuskan_interceptor.json');
ship_manifests.addJsonResource('tuskan_avenger', '/ships/15_tuskan_avenger.json');
ship_manifests.addJsonResource('light_destroyer', '/ships/16_light_destroyer.json');
ship_manifests.addJsonResource('large_freighter', '/ships/17_large_freighter.json');
ship_manifests.addJsonResource('super_freighter', '/ships/18_super_freighter.json');
ship_manifests.addJsonResource('armed_freighter', '/ships/19_armed_freighter.json');
ship_manifests.addJsonResource('missile_destroyer', '/ships/20_missile_destroyer.json');
ship_manifests.addJsonResource('bird_of_prey', '/ships/21_bird_of_prey.json');
ship_manifests.addJsonResource('desolator', '/ships/22_desolator.json');
ship_manifests.addJsonResource('carrier', '/ships/23_carrier.json');

exports.ship_manifests = ship_manifests;