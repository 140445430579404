import React from "react";

export class CircularProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: 0
        }
    }

    setProgress(progress) {
        this.setState({
            progress: progress
        })
    }

    render() {
        const {progress} = this.state;
        const {size, color, trackColor} = this.props;
        const circleSize = 100;
        const borderSize = 8;
        const radius = (circleSize / 2) - borderSize;
        const circumference = 2 * Math.PI * radius;
        const strokeDashoffset = circumference - progress / 100 * circumference;

        return (
            <div className="circular-progress">
                <svg
                    width={circleSize}
                    height={circleSize}
                    viewBox={`0 0 ${circleSize} ${circleSize}`}
                    style={{transform: 'rotate(-90deg)', width: size, height: size}}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        stroke={trackColor}
                        fill="none"
                        strokeWidth={borderSize * 0.75}
                        r={radius}
                        cx={circleSize / 2}
                        cy={circleSize / 2}
                    />
                    <circle
                        stroke={color}
                        fill="none"
                        strokeWidth={borderSize}
                        strokeDasharray={circumference}
                        strokeLinecap="round"
                        style={{strokeDashoffset}}
                        r={radius}
                        cx={circleSize / 2}
                        cy={circleSize / 2}
                    />
                </svg>
                <div className="circular-progress_text">{Math.floor(progress)} %</div>
            </div>
        );
    }
}