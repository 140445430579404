import * as Core from '../../core';

const sounds = new Core.ResourceLoader(null);

sounds.addSoundResource('typekeypress', '/sounds/1_typekeypress.mp3', {});
sounds.addSoundResource('smallexplosion', '/sounds/2_smallexplosion.mp3', {});
sounds.addSoundResource('radiostaticsound', '/sounds/3_radiostaticsound.mp3', {});
sounds.addSoundResource('opening', '/sounds/4_opening.mp3', {});
sounds.addSoundResource('missilefiresound', '/sounds/5_missilefiresound.mp3', {});
sounds.addSoundResource('minor_click1', '/sounds/6_minor_click.mp3', {});
sounds.addSoundResource('minor_click2', '/sounds/7_minor_click.mp3', {});
sounds.addSoundResource('minedropped', '/sounds/8_minedropped.mp3', {});
sounds.addSoundResource('main_click1', '/sounds/9_main_click.mp3', {});
sounds.addSoundResource('main_click2', '/sounds/10_main_click.mp3', {});
sounds.addSoundResource('login_button_press', '/sounds/11_login_button_press.mp3', {});
sounds.addSoundResource('login_but_pops', '/sounds/12_login_but_pops.mp3', {});
sounds.addSoundResource('largeexplosion', '/sounds/13_largeexplosion.mp3', {});
sounds.addSoundResource('guntype6sound', '/sounds/14_guntype6sound.mp3', {});
sounds.addSoundResource('guntype5sound', '/sounds/15_guntype5sound.mp3', {});
sounds.addSoundResource('guntype4sound', '/sounds/16_guntype4sound.mp3', {});
sounds.addSoundResource('guntype3sound', '/sounds/17_guntype3sound.mp3', {});
sounds.addSoundResource('guntype2sound', '/sounds/18_guntype2sound.mp3', {});
sounds.addSoundResource('guntype1sound', '/sounds/19_guntype1sound.mp3', {});
sounds.addSoundResource('guntype0sound', '/sounds/20_guntype0sound.mp3', {});
sounds.addSoundResource('explode1', '/sounds/21_explode1.mp3', {});
sounds.addSoundResource('error1', '/sounds/22_error.mp3', {});
sounds.addSoundResource('error2', '/sounds/23_error.mp3', {});
sounds.addSoundResource('enterpress', '/sounds/24_enterpress.mp3', {});
sounds.addSoundResource('enginesound', '/sounds/25_enginesound.mp3', {});
sounds.addSoundResource('connecting1', '/sounds/26_connecting.mp3', {});
sounds.addSoundResource('connecting2', '/sounds/27_connecting.mp3', {});

exports.sounds = sounds;