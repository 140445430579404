import React from 'react';
import * as Core from '../core';
import * as View from '../view';

import * as PIXI from 'pixi.js';

// Sector container for stars
class SectorContainer extends PIXI.Container {
    constructor(origin_x, origin_y) {
        super();

        this.origin_x = origin_x;
        this.origin_y = origin_y;

        this.stars = null;
        this.line = null;
        this.loaded = false;
        this.zIndex = -1;
    }

    loadLine() {
        this.line = new PIXI.Graphics();
        this.line.lineStyle(10, 0xffffff).drawRect(this.origin_x, this.origin_y, 5000, 5000)
        this.addChild(this.line);
    }

    loadStars() {
        this.stars = new PIXI.Graphics();
        this.addChild(this.stars);
        this.stars.beginFill(0xffffff);

        // Let's add 6000 random stars to this sector.
        for (var i = 0; i < 6000; ++i)
        {
            // Let's pick a random position within this sector.
            this.stars.drawCircle(this.origin_x + Math.random() * 5000, this.origin_y + Math.random() * 5000, Math.random() * 0.5 + 0.5);
        }
        this.stars.endFill();
        this.addChild(this.stars);
    }

    load() {
        if (this.loaded) {
            return;
        }

        this.loadLine();
        this.loadStars();
        
        this.loaded = true;
    }
}

class SceneGui extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.props.scene._gui = this;
    }

    render() {
        return (
            <></>
        );
    }
}

export class AssetTestScene extends Core.GameScene {
    constructor(sc) {
        super(AssetTestScene.ID());
        this.ships = [];
        this.sc = sc;
        this._gui = null;
        this.guiComponent = <SceneGui scene={this}/>;
        this.audio = null;

        this.sectors = [];
        this.last_top_left = {x: Infinity, y: Infinity};
        this.last_bottom_right = {x: Infinity, y: Infinity};
    }

    static ID() {
        return "AssetTestScene";
    }

    onLoad() {
        this.emit(Core.SceneEvent.LOADED);
    }

    onUnload() {
        this.emit(Core.SceneEvent.UNLOADED);
    }

    onResize() {
    }

    onUpdate(delta) {
        this.ships.forEach(ship => {
            let degToRadians = Math.PI / 180;
            ship.rotation += 75 * degToRadians * delta;
            ship.x += Math.sin(ship.rotation) * 110 * delta;
            ship.y += -Math.cos(ship.rotation) * 110 * delta;
        });

        this.updateSectors();
    }

    sectorToKey(x, y) {
        return x + "," + y;
    };

    onTransitionIn() {
        this.count = this.sc.app.resources.ship_sprites.resourceCount();
        this.grid_size = Math.ceil(Math.sqrt(this.count));
        var i = 0;
        for (var y = -25; y < 25; ++y)
        {
            for (var x = -25; x < 25; ++x)
            {
                const sector = new SectorContainer(x * 5000, y * 5000);
                this.sectors[this.sectorToKey(x, y)] = sector;
                this.stage.addChild(sector);
            }
        }
        
        // Let's create each ship in a grid.
        // We'll make it about 5x5 with each area being around 100x100 pixels.
        let shipIndex = 0;
        let ships = [
            "human_scout",
            "human_freighter",
            "human_frigate",
            "human_hornet",
            "feral_raider",
            "feral_freighter",
            "feral_marauder",
            "feral_assaulter",
            "alkari_runner",
            "alkari_hauler",
            "alkari_chaser",
            "alkari_deathwing",
            "tuskan_scout",
            "tuskan_freighter",
            "tuskan_interceptor",
            "tuskan_avenger",
            "light_destroyer",
            "large_freighter",
            "super_freighter",
            "armed_freighter",
            "missile_destroyer",
            "bird_of_prey",
            "desolator",
            "carrier"
        ];
        var ship_load_promise = Promise.resolve();
        for (var y = 0; y < 5; ++y)
        {
            for (var x = 0; x < 5; ++x)
            {
                if (shipIndex >= ships.length) {
                    break;
                }
                const ship = new View.ShipView(ships[shipIndex]);
                ship.load(this.sc.app.resources);
                ship.setShipPosition((x * 100) - 250 + 2500, (y * 100) - 250 + 2500);
                ship.setShieldVisible(true);
                ship.setThrustLevel(1.0, true);
                this.ships.push(ship);
                this.stage.addChild(ship);
                if (shipIndex == 12) {
                    this.sc.viewport.follow(ship);
                }
                shipIndex++;
            }
        }

        this.audio = this.sc.app.resources.sounds.opening.audio;
        let audioEnded = () => {
            // Select a random sound.
            var soundIndex = Math.floor(Math.random() * this.sc.app.resources.sounds.resources.length);
            this.audio = this.sc.app.resources.sounds.resources[soundIndex].audio;
            this.audio.onended = audioEnded;
            //this.audio.play();
        }
        this.audio.onended = audioEnded;
        //this.audio.play();
        this.emit(Core.SceneEvent.TRANSITION_IN_DONE);
    }

    // This function renders a set of stars only within the nearest 3x3 sectors visible on the viewport.
    updateSectors() {
        // Get the current viewport.
        var viewport = this.stage;

        // Get the current viewport center.
        var center = viewport.center;

        // Get the current viewport width and height.
        var width = viewport.screenWidth;
        var height = viewport.screenHeight;

        // Get the current sector.
        var sector_x = Math.floor(center.x / 5000);
        var sector_y = Math.floor(center.y / 5000);

        // Get the top left and bottom right 3x3 sectors.
        var top_left = {x: (sector_x - 1) * 5000, y: (sector_y - 1) * 5000};
        var bottom_right = {x: (sector_x + 1) * 5000, y: (sector_y + 1) * 5000};

        // If the 3x3 viewport has changed since the last time we rendered stars, then we need to update the stars.
        if (top_left.x != this.last_top_left.x || top_left.y != this.last_top_left.y || bottom_right.x != this.last_bottom_right.x || bottom_right.y != this.last_bottom_right.y)
        {
            // Update the 3x3 viewport.
            this.last_top_left = top_left;
            this.last_bottom_right = bottom_right;

            // Remove all sectors containers from the stage.
            this.stage.children.forEach(child => {
                if (child instanceof SectorContainer) {
                    this.stage.removeChild(child);
                }
            });

            // From the top left 3x3 sector, to the bottom right 3x3 sector, let's add a bunch of stars, randomly.
            for (var y = Math.floor(top_left.y / 5000); y <= Math.floor(bottom_right.y / 5000); ++y)
            {
                for (var x = Math.floor(top_left.x / 5000); x <= Math.floor(bottom_right.x / 5000); ++x)
                {
                    if (this.sectors[this.sectorToKey(x, y)])
                    {
                        // Load the sector. If it's already loaded it will just immediately return.
                        this.sectors[this.sectorToKey(x, y)].load();

                        // Add it to the stage.
                        this.stage.addChild(this.sectors[this.sectorToKey(x, y)]);
                    }
                }
            }
        }
    }

    onTransitionOut() {
        this.emit(Core.SceneEvent.TRANSITION_OUT_DONE);
    }
}