import React from 'react'
import { Viewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'

const oldGameHeight = 550;

export class Renderer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.RenderCanvas = React.createRef();

        this.onRenderLoad = props.onLoad;
        this.onRenderUnload = props.onUnload;
        this.viewport = null;
    }

    getViewportScale()
    {
        return window.innerHeight / oldGameHeight;
    }

    onResize() {
        window.sc.app.renderer.resize(window.innerWidth, window.innerHeight);
        if (this.viewport)
        {
            var scale = this.getViewportScale();
            this.viewport.scale.set(scale, scale);
            
            this.viewport.clampZoom({
                minScale: scale,
                maxScale: scale
            })
        }
        if (window.sc.stageManager) window.sc.stageManager.resize();
    }

    componentDidMount() {
        this.RenderCanvas.current.appendChild(window.sc.app.view);
        window.addEventListener('resize', this.onResize.bind(this));
        this.onResize();

        this.viewport = new Viewport({
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
            worldWidth: 250000,
            worldHeight: 250000,
            interaction: window.sc.app.renderer.plugins.interaction
        });
        this.viewport.sortableChildren = true;

        // add the viewport to the stage
        window.sc.app.stage.addChild(this.viewport);
        
        // activate plugins
        this.viewport
            .wheel()
            .clampZoom({
                minScale: this.getViewportScale(),
                maxScale: this.getViewportScale()
            })
        
        this.onRenderLoad(this);
        this.onResize();
    }

    componentWillUnmount() {
        this.onRenderUnload();
        window.removeEventListener('resize', this.onResize.bind(this));
        this.RenderCanvas.current.removeChild(window.sc.app.view);
    }

    render() {
        return (
            <div ref={this.RenderCanvas}></div>
        )
    }
}