import React from 'react'

import * as Core from "./core";
import * as SharedCore from "../shared/core";
import * as Scenes from './scenes';
import {resources} from './resources';
import * as PIXI from 'pixi.js'

import {__BUILDNUM__, __VERSION__} from "../../dist/shared/config.js";

export class SCApp extends React.Component {
    constructor(props) {
        super(props);
        this.app = new PIXI.Application({
            resolution: window.devicePixelRatio,
            autoDensity: true,
            antialias: true
        });
        window.PIXI = PIXI;
        window.sc = this;
        window.scLoader = new SharedCore.AssetLoader(new Core.XHRLoader());
        this.app.resources = resources;
        
        this.stageManager = new Core.StageManager(this);

        console.log(`SC Web Client v${__VERSION__} Build: ${__BUILDNUM__}`);
    }

    onRenderLoad(renderComponent) {
        this.renderComponent = renderComponent;
        this.viewport = renderComponent.viewport;
        console.log(`Renderer Loaded!`);
    }

    onRenderUnload(app) {
        console.log(`Renderer Unloaded!`);
    }

    onStageManagerGuiLoad(guiComponent) {
        this.guiComponent = guiComponent;
        console.log(`StageManagerGui Loaded!`);

        this.stageManager.add(new Scenes.PreloaderScene(this));
        this.stageManager.add(new Scenes.AssetTestScene(this));

        this.app.resources.onProgress((progress) => {
                if (this.stageManager.loaded && this.stageManager.loaded.setProgress)
                {
                    this.stageManager.loaded.setProgress(progress * 100.0);
                }
            }
        );

        this.stageManager.play(Scenes.PreloaderScene.ID());

        this.app.resources.onLoad((resources) => {
            const ws = new WebSocket("wss://dev.stellar-conflicts.com/socket/");

            ws.onopen = () => {
                ws.send("Good morning!");
            };

            ws.onmessage = msg => {
                // Parse preamble.
                if (msg.data.startsWith("DATA`")) {
                    this.model = JSON.parse(msg.data.substring(5));
                }
            };

            console.log("Added tickker");
            this.app.ticker.add((delta) => {
                this.stageManager.tick(this.app.ticker.deltaMS / 1000.0);
            });
        });

        this.app.resources.load();
    }

    onStageManagerGuiUnload(guiComponent) {
        console.log(`StageManagerGui unloaded!`);
    }

    render() {
        return (
            <div>
                <Core.Renderer onLoad={this.onRenderLoad.bind(this)} onUnload={this.onRenderUnload.bind(this)} />
                <Core.StageManagerGui stageManager={this.stageManager} onLoad={this.onStageManagerGuiLoad.bind(this)} onUnload={this.onStageManagerGuiUnload.bind(this)} />
            </div>
        )
    }
}