import * as PIXI from 'pixi.js';

import * as Core from '../../core';

const turrets = new Core.ResourceLoader(null);

const svgLoadOptions = {
    resourceOptions: { 
        width: 96,
        height: 160
     },
    mipmap: PIXI.MIPMAP_MODES.ON
};

turrets.addTextureResource('turret0', '/sprites/turrets/turret0.svg', svgLoadOptions);
turrets.addTextureResource('turret1', '/sprites/turrets/turret1.svg', svgLoadOptions);
turrets.addTextureResource('turret2', '/sprites/turrets/turret2.svg', svgLoadOptions);

exports.turrets = turrets;