import { ship_sprites } from './ship_sprites';
import { shields } from './shields';
import { thrusters } from './thrusters';
import { sounds } from './sounds';
import { turrets } from './turrets';
import { ship_manifests } from './ship_manifests';

import * as Core from '../core';

const resources = new Core.ResourceLoader(null);

resources.addLoader('ship_sprites', ship_sprites);
resources.addLoader('shields', shields);
resources.addLoader('thrusters', thrusters);
resources.addLoader('sounds', sounds);
resources.addLoader('turrets', turrets);
resources.addLoader('ship_manifests', ship_manifests);

exports.resources = resources;