import * as PIXI from 'pixi.js';

export const SceneEvent = Object.freeze({
    LOADED: 'loaded',
    UNLOADED: 'unloaded',
    TRANSITION_OUT_DONE: 'transition out done',
    TRANSITION_IN_DONE: 'transition in done',
});

export class GameScene {
    constructor(alias) {
        this.alias = alias;
        this.events = {};
        this.stage = new PIXI.Container();
    }

    onLoad() {

    }

    onUnload() {

    }

    onTransitionIn() {

    }

    onTransitionOut() {

    }

    onTransitionInUpdate() {

    }

    onTransitionOutUpdate() {

    }

    onUpdate() {

    }

    onResize() {
        
    }

    when(event, callback) {
        this.events[event] = this.events[event] || [];
        this.events[event].push(callback);
    }

    emit(event) {
        if (!this.events.hasOwnProperty(event)) {
            return;
        }
        this.events[event].forEach(cb => {
            cb(event, this);
        });
    }

    clearListeners() {
        this.events = {};
    }
}