import React from 'react'

import {SceneEvent, GameScene} from "./GameScene";

export const StageState = Object.freeze({
    STOPPED:         Symbol("stopped"),
    LOADING:         Symbol("loading"),
    UNLOADING:       Symbol("unloading"),
    TRANSITION_OUT:  Symbol("transition out"),
    TRANSITION_IN:   Symbol("transition in"),
    PLAYING:         Symbol("playing")
});

export class StageManagerGui extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentScene: 'default'
        }
        this.guiComponents = {'default': <></>};
        this.stageManager = this.props.stageManager;
        this.onStageLoad = props.onLoad;
        this.onStageUnload = props.onUnload;
    }

    registerGui(scene, component) {
        this.guiComponents[scene.alias] = component;
    }

    componentDidMount() {
        this.stageManager.setRoot(this);
        this.stageManager.onLoad(() => {
            if (this.stageManager.loaded) {
                this.setState({currentScene: this.stageManager.loaded.alias});
            }
        });

        this.onStageLoad();
    }

    componentWillUnmount() {
        this.onStageUnload();
    }

    render() {
        return (
            <div id='gui'>
                {this.guiComponents[this.state.currentScene]}
            </div>
        );
    }
}

export class StageManager
{
    constructor(sc) {
        this.sc = sc;
        this.events = {};
        this.scenes = {};
        this.loaded = null;
        this.next = null;
        this.state = StageState.STOPPED;
        this.guiComponent = null;
    }

    setRoot(gui)
    {
        this.guiRoot = gui;
    }

    add(scene) {
        this.scenes[scene.alias] = scene;
        this.guiRoot.registerGui(scene, scene.guiComponent);
        return this;
    }

    remove(scene) {
        if (this.loaded == scene) {
            throw 'You cannot remove the current scene.';
        }
        delete this.scenes[scene.alias];
        return this;
    }

    play(sceneName) {
        if (!this.scenes.hasOwnProperty(sceneName))
        {
            throw 'Scene does not exist!';
        }

        if (this.state != StageState.STOPPED) {
            throw 'Stage Already In Progress. Please call goto().';
        }
        
        this.goto(sceneName);
    }

    goto(sceneName) {
        if (!this.scenes.hasOwnProperty(sceneName))
        {
            throw 'Scene does not exist!';
        }
        if (this.state != StageState.PLAYING && this.state != StageState.STOPPED) {
            throw 'Cannot change stages during a transition.';
        }

        this.next = this.scenes[sceneName];
        this.next.when(SceneEvent.LOADED, (event, scene) => { this.handle(event, scene) });
        this.state = StageState.LOADING;
        this.next.onLoad();
    }

    // @func onLoad
    // Registers a callback that is called when a scene is loaded.
    // @param function callback
    onLoad(callback) {
        this.when(SceneEvent.LOADED, callback);
    }

    when(event, callback) {
        this.events[event] = this.events[event] || [];
        this.events[event].push(callback);
    }

    emit(event) {
        if (!this.events.hasOwnProperty(event)) {
            return;
        }
        this.events[event].forEach(cb => {
            cb(event, this);
        });
    }

    clearListeners() {
        this.events = {};
    }

    handle(event, scene) {
        switch(event) {
            case SceneEvent.LOADED:
                if (this.loaded) {
                    this.state = StageState.TRANSITION_OUT;
                    this.loaded.when(SceneEvent.TRANSITION_OUT_DONE, (event, scene) => { this.handle(event, scene); });
                    this.loaded.onTransitionOut();
                } else {
                    this.sc.viewport.removeChildren();
                    this.next.stage = this.sc.viewport;
                    this.state = StageState.TRANSITION_IN;
                    this.next.when(SceneEvent.TRANSITION_IN_DONE, (event, scene) => { this.handle(event, scene); });
                    this.next.onTransitionIn();
                }
                this.emit(SceneEvent.LOADED);
                return;
            case SceneEvent.TRANSITION_OUT_DONE:
                if (this.loaded) {
                    this.sc.viewport.removeChildren();
                    this.state = StageState.UNLOADING;
                    scene.when(SceneEvent.UNLOADED, (event, scene) => { this.handle(event, scene) });
                    scene.onUnload();
                } else {
                    this.next.stage = this.sc.viewport;
                    this.state = StageState.TRANSITION_IN;
                    this.next.when(SceneEvent.TRANSITION_IN_DONE, (event, scene) => { this.handle(event, scene); });
                    this.next.onTransitionIn();
                }
                return;
            case SceneEvent.UNLOADED:
                this.next.stage = this.sc.viewport;
                this.state = StageState.TRANSITION_IN;
                this.next.when(SceneEvent.TRANSITION_IN_DONE, (event, scene) => { this.handle(event, scene); });
                this.next.onTransitionIn();
                return;
            case SceneEvent.TRANSITION_IN_DONE:
                this.state = StageState.PLAYING;
                this.loaded = this.next;
                this.next = null;
                this.guiComponent = this.loaded.guiComponent;
                return;
        }
    }

    resize() {
        if (this.loaded) {
            this.loaded.stage.resize();
            this.loaded.onResize();
        }
    }

    tick(delta) {
        if (this.loaded) {
            if (this.stage == StageState.TRANSITION_IN) {
                this.loaded.onTransitionInUpdate(delta);
            } else if (this.stage == StageState.TRANSITION_OUT) {
                this.loaded.onTransitionOutUpdate(delta);
            } else {
                this.loaded.onUpdate(delta);
            }
        }
    }
}