import * as PIXI from 'pixi.js'

const ResourceTypes = Object.freeze({
    TEXTURE: 'texture',
    AUDIO: 'audio',
    JSON: 'json'
});

export class ResourceLoader {
    constructor(parent) {
        this.subLoaders = [];
        this.resources = [];
        this.loaded_resources = 0;
        this.total_resources = 0;
        this.finished_resources = true;
        this.finished_loaders = true;
        this.parent = parent;
        this._root = (this.parent != null) ? this.parent : this;

        this.subLoaded = 0;

        this.loaded_callbacks = [];
        this.progress_callbacks = [];
    }

    addLoader(alias, loader) {
        if (this.hasOwnProperty(alias)) {
            throw 'The alias must be unique!';
        }

        this.finished_loaders = false;

        this.subLoaders.push(loader);
        this[alias] = loader;
        loader.parent = this;
        this._root = this.root();
        loader._root = this._root;
        loader.onLoad(l => {
            this.handleChildLoaded();
        });
    }

    addJsonResource(alias, url) {
        if (this.hasOwnProperty(alias)) {
            throw 'The alias must be unique!';
        }

        this.finished_resources = false;

        var resource = {
            url: url,
            loaded: false,
            options: {},
            alias: alias,
            type: ResourceTypes.JSON,
            data: {}
        };
        this.resources.push(resource);
        this[alias] = resource;
        this.total_resources++;
    }

    addTextureResource(alias, url, options) {
        if (this.hasOwnProperty(alias)) {
            throw 'The alias must be unique!';
        }

        this.finished_resources = false;

        var resource = {
            url: url,
            loaded: false,
            options: options,
            alias: alias,
            type: ResourceTypes.TEXTURE,
            texture: {}
        };
        this.resources.push(resource);
        this[alias] = resource;
        this.total_resources++;
    }

    addSoundResource(alias, url, options) {
        if (this.hasOwnProperty(alias)) {
            throw 'The alias must be unique!';
        }

        this.finished_resources = false;

        var resource = {
            url: url,
            loaded: false,
            options: options,
            alias: alias,
            type: ResourceTypes.AUDIO,
            audio: {}
        };
        this.resources.push(resource);
        this[alias] = resource;
        this.total_resources++;
    }

    load() {
        this.subLoaders.forEach(loader => {
            loader.load();
        });
        this.resources.forEach(resource => {
            if (resource.type == ResourceTypes.TEXTURE) {
                resource.options.resolution = PIXI.settings.RESOLUTION;
                resource.texture = PIXI.Texture.from(
                    resource.url,
                    resource.options
                );
                resource.texture.baseTexture.loader = this;
                resource.texture.baseTexture.on('loaded', texture => {
                    texture.loader.loaded_resources++;
                    texture.loader.emitProgress(texture.loader.progress())
                    if (texture.loader.loaded_resources == texture.loader.total_resources) {
                        this.finished_resources = true;
                    }
    
                    if (this.finished_resources && this.finished_loaders) {
                        this.emitLoad(this);
                    }
                });
            } else if (resource.type == ResourceTypes.AUDIO) {
                resource.audio = new Audio(resource.url);
                resource.audio.loader = this;
                resource.audio.oncanplaythrough = (event) => {
                    let audio = event.target;
                    audio.oncanplaythrough = null;
                    audio.loader.loaded_resources++;
                    audio.loader.emitProgress(audio.loader.progress());
                    if (audio.loader.loaded_resources == audio.loader.total_resources) {
                        this.finished_resources = true;
                    }

                    if (this.finished_resources && this.finished_loaders) {
                        this.emitLoad(this);
                    }
                };
                resource.audio.load();
            } else if (resource.type == ResourceTypes.JSON) {
                window.scLoader.load(resource.url).then(data => {
                    resource.data = JSON.parse(data);
                    resource.loaded = true;
                    this.loaded_resources++;
                    this.emitProgress(this.progress());
                    if (this.loaded_resources == this.total_resources) {
                        this.finished_resources = true;
                    }

                    if (this.finished_resources && this.finished_loaders) {
                        this.emitLoad(this);
                    }
                });
            }
        });
    }

    handleChildLoaded() {
        this.subLoaded++;
        if (this.subLoaded == this.subLoaders.length) {
            this.finished_loaders = true;
        }

        if (this.finished_resources && this.finished_loaders) {
            this.emitLoad(this);
        }
    }

    resourceCount() {
        let total = this.total_resources;
        this.subLoaders.forEach(loader => {
            total += loader.resourceCount();
        });
        return total;
    }

    resourcesLoaded() {
        let loaded = this.loaded_resources;
        this.subLoaders.forEach(loader => {
            loaded += loader.resourcesLoaded();
        });
        return loaded;
    }

    progress() {
        return this.root().resourcesLoaded() / this.root().resourceCount();
    }

    root() {
        if (this.parent == null) {
            return this;
        }
        let parent = this.parent;
        while (parent.parent != null) {
            parent = parent.parent;
        };
        return parent;
    }

    onProgress(func) {
        this._root.progress_callbacks.push(func);
    }

    emitProgress(progress) {
        this._root.progress_callbacks.forEach(f => {
            f(progress);
        });
    }

    onLoad(func) {
        this.loaded_callbacks.push(func);
    }

    emitLoad(resources) {
        this.loaded_callbacks.forEach(f => {
            f(resources);
        });
    }
}