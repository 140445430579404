import * as PIXI from 'pixi.js';

import * as Core from '../../core';

const thrusters = new Core.ResourceLoader(null);

const svgLoadOptions = {
    resourceOptions: { 
        width: 80,
        height: 155
     },
    mipmap: PIXI.MIPMAP_MODES.ON
};

thrusters.addTextureResource('thruster0_frame0', '/sprites/thrusters/engine0_0.svg', svgLoadOptions);
thrusters.addTextureResource('thruster0_frame1', '/sprites/thrusters/engine0_1.svg', svgLoadOptions);
thrusters.addTextureResource('thruster0_frame2', '/sprites/thrusters/engine0_2.svg', svgLoadOptions);
thrusters.addTextureResource('thruster0_frame3', '/sprites/thrusters/engine0_3.svg', svgLoadOptions);
thrusters.addTextureResource('thruster0_frame4', '/sprites/thrusters/engine0_4.svg', svgLoadOptions);
thrusters.addTextureResource('thruster0_frame5', '/sprites/thrusters/engine0_5.svg', svgLoadOptions);
thrusters.addTextureResource('thruster0_frame6', '/sprites/thrusters/engine0_6.svg', svgLoadOptions);

exports.thrusters = thrusters;