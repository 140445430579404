import * as PIXI from 'pixi.js';

import * as Core from '../../core';

const ship_sprites = new Core.ResourceLoader(null);

const svgLoadOptions = {
    resourceOptions: { 
        width: 512,
        height: 512
     },
    mipmap: PIXI.MIPMAP_MODES.ON
};

ship_sprites.addTextureResource('human_scout', '/sprites/ships/0_human_scout.svg', svgLoadOptions);
ship_sprites.addTextureResource('human_freighter', '/sprites/ships/1_human_freighter.svg', svgLoadOptions);
ship_sprites.addTextureResource('human_frigate', '/sprites/ships/2_human_frigate.svg', svgLoadOptions);
ship_sprites.addTextureResource('human_hornet', '/sprites/ships/3_human_hornet.svg', svgLoadOptions);
ship_sprites.addTextureResource('feral_raider', '/sprites/ships/4_feral_raider.svg', svgLoadOptions);
ship_sprites.addTextureResource('feral_freighter', '/sprites/ships/5_feral_freighter.svg', svgLoadOptions);
ship_sprites.addTextureResource('feral_marauder', '/sprites/ships/6_feral_marauder.svg', svgLoadOptions);
ship_sprites.addTextureResource('feral_assaulter', '/sprites/ships/7_feral_assaulter.svg', svgLoadOptions);
ship_sprites.addTextureResource('alkari_runner', '/sprites/ships/8_alkari_runner.svg', svgLoadOptions);
ship_sprites.addTextureResource('alkari_hauler', '/sprites/ships/9_alkari_hauler.svg', svgLoadOptions);
ship_sprites.addTextureResource('alkari_chaser', '/sprites/ships/10_alkari_chaser.svg', svgLoadOptions);
ship_sprites.addTextureResource('alkari_deathwing', '/sprites/ships/11_alkari_deathwing.svg', svgLoadOptions);
ship_sprites.addTextureResource('tuskan_scout', '/sprites/ships/12_tuskan_scout.svg', svgLoadOptions);
ship_sprites.addTextureResource('tuskan_freighter', '/sprites/ships/13_tuskan_freighter.svg', svgLoadOptions);
ship_sprites.addTextureResource('tuskan_interceptor', '/sprites/ships/14_tuskan_interceptor.svg', svgLoadOptions);
ship_sprites.addTextureResource('tuskan_avenger', '/sprites/ships/15_tuskan_avenger.svg', svgLoadOptions);
ship_sprites.addTextureResource('light_destroyer', '/sprites/ships/16_light_destroyer.svg', svgLoadOptions);
ship_sprites.addTextureResource('large_freighter', '/sprites/ships/17_large_freighter.svg', svgLoadOptions);
ship_sprites.addTextureResource('super_freighter', '/sprites/ships/18_super_freighter.svg', svgLoadOptions);
ship_sprites.addTextureResource('armed_freighter', '/sprites/ships/19_armed_freighter.svg', svgLoadOptions);
ship_sprites.addTextureResource('missile_destroyer', '/sprites/ships/20_missile_destroyer.svg', svgLoadOptions);
ship_sprites.addTextureResource('bird_of_prey', '/sprites/ships/21_bird_of_prey.svg', svgLoadOptions);
ship_sprites.addTextureResource('desolator', '/sprites/ships/22_desolator.svg', svgLoadOptions);
ship_sprites.addTextureResource('carrier', '/sprites/ships/23_carrier.svg', svgLoadOptions);

exports.ship_sprites = ship_sprites;