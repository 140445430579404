export class AssetLoader
{
    constructor(loaderImplementation)
    {
        this.loaderImplementation = loaderImplementation;
    }

    load(url) {
        return new Promise((resolve, reject) => {
            this.loaderImplementation.load(url, resolve, reject);
        });
    }
}