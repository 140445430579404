import * as PIXI from 'pixi.js';

/**
 * @class ShipView
 * ShipView is the 2D representation of a Ship in the game world.
 * 
 * Loads the ship's various sprites and animations from a json file that contains
 * the ship sprite, the animated thruster sprites, the animated shield sprite,
 * origin information as well as radius of the ship.
 * The json file also contains the thruster, shield, and turret positions relative
 * to the ship's origin.
 * 
 * ShipView contains a copy of the angle of the ship's turrets as well as the ship's.
 * ShipView has a method to set the thrust level of the ship which will cause the thruster
 * sprite frame to change.
 * 
 * ShipView has a method to set the shield level of the ship which will cause the shield
 * animation to be visible and play.
 * 
 * ShipView also can enable visiblity of small lightning bolts that are emitted from the
 * ship radius when the ship hull is very damaged.
 */
export class ShipView extends PIXI.Container {
    constructor(shipManifestName)
    {
        super();
        this.shipManifestName = shipManifestName;
        this.shipSprite = null;
        this.thrusterSprites = [];
        this.shieldSprite = null;
        this.lightningSprite = null;
        this.turretSprites = [];
        this.resources = null;
        this.isCapital = false;
    }

    /**
     * @method load
     * Asynchronously loads the ship's sprites and animations from the json file.
     * 
     * Format of the json file:
     * {
     *    "ship": {
     *       "sprite": "ship.svg",
     *       "origin": { x: 0.5, y: 0.5 },
     *       "radius": 0.5,
     *    },
     *   "thrustFrames": ["thruster0.svg", "thruster1.svg"],
     *   "thrustOrigin": { x: 0.5, y: 0.5 },
     *   "thrustLocations": [
     *      { x: 0.5, y: 0.5 },
     *      { x: 0.5, y: 0.5 },
     *    ],
     *   "shield": {
     *      "frames": ["shield0.svg", "shield1.svg", "shield2.svg", "shield3.svg"],
     *      "origin": { x: 0.5, y: 0.5 },
     *      "location": { x: 0.5, y: 0.5 },
     *      "animationSpeed": 0.1,
     *   },
     *  "turretSprite": "turret.svg",
     *  "turretOrigin": { x: 0.5, y: 0.5 },
     *  "turretLocations": [
     *       { x: 0.5, y: 0.5 },
     *       { x: 0.5, y: 0.5 },
     *   ],
     *   "lightningSprite": "lightning.svg",
     *   "isCapital": false
     * }
     */
    load(resources)
    {
        this.resources = resources;
        const shipJson = resources.ship_manifests[this.shipManifestName].data;
        this._loadShipSprite(shipJson);
        this._loadShieldSprite(shipJson);
        this._loadThrusterSprites(shipJson);
        this._loadTurretSprites(shipJson);
        this._loadLightningSprite(shipJson);
    }

    /**
     * @method _loadShipSprite
     * Loads the ship sprite from the json file.
     */
    _loadShipSprite(shipJson)
    {
        this.isCapital = shipJson.isCapital;
        const shipSprite = new PIXI.Sprite(this.resources.ship_sprites[shipJson.ship.sprite].texture);
        shipSprite.height = 86;
        shipSprite.width = 86;
        shipSprite.anchor.set(shipJson.ship.origin.x, shipJson.ship.origin.y);
        this.shipSprite = shipSprite;
        this.addChild(shipSprite);
    }

    /**
     * @method _loadThrusterSprites
     * Loads the thruster sprites from the json file.
     */
    _loadThrusterSprites(shipJson)
    {
        const thrustOrigin = shipJson.thrustOrigin;
        const thrustLocations = shipJson.thrustLocations;
        const thrustFrames = shipJson.thrustFrames;
        thrustLocations.forEach((location, index) => {
            let textureArray = [];
            thrustFrames.forEach((frame) => {
                textureArray.push(this.resources.thrusters[frame].texture);
            });
            const sprite = new PIXI.AnimatedSprite(textureArray);
            sprite.width = 7;
            sprite.height = 14;
            sprite.anchor.set(thrustOrigin.x, thrustOrigin.y);
            this.attachToShip(sprite, location.x, location.y, false);
            this.thrusterSprites.push(sprite);
            this.addChild(sprite);
        });
    }

    /**
     * @method _loadShieldSprite
     * Loads the shield sprite from the json file.
     */
    _loadShieldSprite(shipJson)
    {
        const shieldJson = shipJson.shield;
        let textureArray = [];
        shieldJson.frames.forEach((frame) => {
            textureArray.push(this.resources.shields[frame].texture);
        });
        const shieldSprite = new PIXI.AnimatedSprite(textureArray);
        shieldSprite.height = this.isCapital ? 100 : 86;
        shieldSprite.width = this.isCapital ? 100 : 86;
        this.attachToShip(shieldSprite, shieldJson.location.x, shieldJson.location.y, true);
        shieldSprite.animationSpeed = shieldJson.animationSpeed;
        this.shieldSprite = shieldSprite;
        this.addChild(shieldSprite);
        this.shieldSprite.visible = false;
    }

    /**
     * @method _loadTurretSprites
     * Loads the turret sprites from the json file.
     */
    _loadTurretSprites(shipJson)
    {
        const turretOrigin = shipJson.turretOrigin;
        const turretLocations = shipJson.turretLocations;
        const turretSprite = shipJson.turretSprite;
        turretLocations.forEach((location, index) => {
            const sprite =  new PIXI.Sprite(this.resources.turrets[turretSprite].texture);
            sprite.width = 8;
            sprite.height = 13;
            sprite.anchor.set(turretOrigin.x, turretOrigin.y);
            this.attachToShip(sprite, location.x, location.y, false);
            this.turretSprites.push(sprite);
            this.addChild(sprite);
        });
    }

    /**
     * @method _loadLightningSprite
     * Loads the lightning sprite from the json file.
     */
    _loadLightningSprite(shipJson)
    {
        return; // TODO: Implement lightning sprite.
        const lightningSprite = new PIXI.Sprite(this.resources.misc[shipJson.lightningSprite].texture);
        lightningSprite.anchor.set(0.5, 0.5);
        lightningSprite.visible = false;
        this.lightningSprite = lightningSprite;
        this.addChild(lightningSprite);
    }

    /**
     * @method setTurretAngle
     * Sets the angle of the turret at the given index. Rotates the turret sprite to the given angle.
     * - index: the index of the turret to set the angle of.
     * - angle: the angle to set the turret to.
     */
    setTurretAngle(index, angle)
    {
        this.turretSprites[index].rotation = angle;
    }

    /**
     * @method setShieldVisible
     * Sets the visibility of the shield.
     * - visible: true to show the shield, false to hide it.
     */
    setShieldVisible(visible)
    {
        if (visible)
        {
            // Show the shield.
            this.shieldSprite.visible = true;
            this.shieldSprite.play();
        }
        else
        {
            // Hide the shield.
            this.shieldSprite.visible = false;
            this.shieldSprite.stop();
        }
    }

    /**
     * @method setThrustLevel
     * Sets the thrust level of the ship.
     * - shipSpeed Percentage of the ship's maximum speed to thrust at.
     * - afterburner: true to show the afterburner, false to hide it.
     * 
     * The afterburner means it shows the last frame of the thruster animation.
     * Otherwise, shipSpeed is multiplied by one less than the maximum frames
     *  and rounded down to the nearest integer.
     * 
     * If shipSpeed is exactly 0, the thrusters are hidden.
     */
    setThrustLevel(shipSpeed, afterburner)
    {
        if (shipSpeed === 0)
        {
            // Hide the thrusters.
            this.thrusterSprites.forEach(sprite => sprite.visible = false);
        }
        else
        {
            // Show the thrusters.
            this.thrusterSprites.forEach((sprite, index) => {
                sprite.visible = true;
                if (afterburner)
                {
                    // Show the afterburner.
                    sprite.gotoAndStop(sprite.totalFrames - 1);
                }
                else
                {
                    // Show the thruster animation.
                    const frame = Math.floor(shipSpeed * (sprite.totalFrames - 1));
                    sprite.gotoAndStop(frame);
                }
            });
        }
    }

    /**
     * @method setShipAngle
     * Sets the angle of the ship.
     * - angle: the angle to set the ship to.
     */
    setShipAngle(angle)
    {
        this.rotation = angle;
    }

    /**
     * @method setShipPosition
     * Sets the position of the ship.
     * - x: the x position to set the ship to.
     * - y: the y position to set the ship to.
     */
    setShipPosition(x, y)
    {
        this.position.set(x, y);
    }

    /**
     * @method attachToShip
     * Attaches a sprite to the ship making sure to account for the ship's anchor point.
     * - sprite: the sprite to attach to the ship.
     * - x: the x position to attach the sprite to. (0 through 1.0 corresponding to percentage of width of ship.)
     * - y: the y position to attach the sprite to. (0 through 1.0 corresponding to percentage of height of ship.)
     */
    attachToShip(sprite, x, y, isShield)
    {
        // The ship's position at 0, 0 is the center of the ship's anchor point.
        // Our x and y correspond to the location within the ship's sprite dimensions.
        // So we need to offset the x and y by the ship's anchor point.
        let shipWidth = this.shipSprite.width;
        let shipHeight = this.shipSprite.height;
        if (this.isCapital && isShield) {
            shipWidth = 100;
            shipHeight = 100;
        }

        const xOffset = x * shipWidth - shipWidth * this.shipSprite.anchor.x;
        const yOffset = y * shipHeight - shipHeight * this.shipSprite.anchor.y;
        sprite.position.set(xOffset, yOffset);
    }

}