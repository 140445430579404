import React from 'react';
import * as Core from '../core';
import * as Gui from "../gui";

import * as PIXI from 'pixi.js';

import {AssetTestScene} from "./AssetTestScene";

class SceneGui extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
        this.props.scene._gui = this;
        this.progress = React.createRef();
    }

    setProgress(amt) {
        this.progress.current.setProgress(amt);
        if (amt >= 100) {
            this.setState({
                loaded: true
            })
        }
    }

    renderLoaded() {
        return (
            <button className="interact" onClick={() => {
                this.props.scene.loadGame();
            }}>Click Here to Begin</button>
        );
    }

    renderProgress() {
        return (
            <Gui.CircularProgress ref={this.progress} size="26vh" color="#62de4f" trackColor="#0f2010" />
        );
    }

    render() {
        return (
            <>
                {
                    this.state.loaded ? this.renderLoaded() : this.renderProgress()
                }
            </>
        );
    }
}

export class PreloaderScene extends Core.GameScene {
    constructor(sc) {
        super(PreloaderScene.ID());
        this.sc = sc;
        this._gui = null;
        this.guiComponent = <SceneGui scene={this}/>;
    }

    static ID() {
        return "PreloaderScene";
    }

    onLoad() {
        this.emit(Core.SceneEvent.LOADED);
    }

    onUnload() {
        this.emit(Core.SceneEvent.UNLOADED);
    }

    setProgress(amt) {
        if (this._gui) {
            this._gui.setProgress(amt);
        }
    }

    loadGame() {
        this.sc.stageManager.goto(AssetTestScene.ID());
    }

    onResize() {
    }

    onUpdate() {

    }

    onTransitionIn() {
        this.emit(Core.SceneEvent.TRANSITION_IN_DONE);
    }

    onTransitionOut() {
        this.emit(Core.SceneEvent.TRANSITION_OUT_DONE);
    }
}